@font-face {
  font-family: 'CatchyMelodyRegular';
  /* src: url(assets/fonts/CandyCake-gxEyP.ttf); */
  src: url(./fonts/BubbleBobble-MVpLp.otf);
}

.font-child {
  font-family: CatchyMelodyRegular, Courier !important;
}
.font-secondary {
  font-family: 'Indie Flower', cursive;
}

@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';
@import 'custome.scss';
