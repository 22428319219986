@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;600;700;800&family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.trait_question_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .trait_left_side {
        // background: red;
        width: 50%;
        img {
            position: relative;
            object-fit: fill !important;
        }
    }
    .card{
        justify-content: center;
        display: flex;
        flex-direction: row;
        /* padding: 40px 0px 40px 0px; */
        padding: 40px 0px;
        border-radius: 30px;
    }

    .trait_right_side {
        width: 50%;
        background: #FFF5F5;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .text.trait_text{
            display: flex;
            p {
                margin-top: 0;
                margin-bottom: 1rem;
                font-size: 80px;
                line-height: 1;
                word-wrap: break-word;
                text-align: center;
                font-weight: 500;
                font-family: CatchyMelodyRegular, Courier !important;

            }



        }
    }

    a.get_started {
        background: #FF9695;
        padding: 20px;
        border-radius: 10px;
        color: white;
        font-size: 25px;
        font-family: 'Overpass', sans-serif;
        font-weight: 600;
        text-transform: uppercase;

        img.arrow_icon{
            height: 15px;
            width: 15px;
        }
    }
}

.question_wrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    padding: 10px 40px;
    .card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px 70px 20px 70px;

        border-radius: 30px;

    }

    .finish__button{
        margin-top: 20px;
    }

    .images {
        // height: 350px;
        // width: 350px;
        position: relative;
        overflow: none;

        img.image {
            position: relative;
            object-fit: contain !important;
            height: 350px;
            align-self: center;
        }
    }
    .question_no{
        text-align: center;
        p.count {
            color: #9A4A4D;
            text-transform: uppercase;
            font-weight: 700;

        }
    }

    .title {
        text-align: center;
        // background: aqua;
        position: relative;
        text-transform: uppercase;
        p{
            font-size: 20px;
            color: #BF070E;
            font-weight: 500;        }
    }

    .question_box{
        width: 70%;
        p.question{
            font-size: 35px;
            color: #9A4A4D;
            text-align: center;
            font-weight: 500;
            // line-height: 10px;
            font-family: CatchyMelodyRegular, Courier !important;
            line-height: 40px;
        }

    }
    .choices{
        gap: 20px;
        display: flex;
        margin-top: 30px;
        .multiple_choice{
            padding: 10px;
            border-radius: 12px;
            background: #FF9695;
            color: white;
            font-weight: 500;
            width: 210px;
            word-break: break-word;
            height: 100px;
            font-size: 20px;
            text-transform: uppercase;
            border: none;
            transition: all .5s;
            font-family: CatchyMelodyRegular, Courier !important;
            &:hover{
                background-color: #9A4A4D;
            }

            &.selected{
                background-color: #9A4A4D;
            }
        }
    }
}

.final_screen_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title{
        width: 100%;
        color: #BF070E;
        text-transform: uppercase;
        text-align: center;
        
        
        p.congrats_title{
            font-size: 35px;
            font-weight: 500;
            font-family: CatchyMelodyRegular, Courier !important;

        }
    }

    .card{
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        border-radius: 30px;
    }
    .description{
        width: 55%;
        overflow: hidden;
        text-align: center;
        word-wrap: break-word;
        font-weight: 500;
        font-family: CatchyMelodyRegular, Courier !important;


        p.drap_description {
            /* text-transform: capitalize; */
            color: #9A4A4D;
            font-size: 28px;
            font-weight: 600;
            line-height: 30px;
        }
    }

    .tabs{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .single_tab{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;
            .tab{
                
                width: 22rem;
                height: 3rem;
                padding: 5px 40px;
                text-align: center;
                color: white;
                text-transform: uppercase;
                font-size: 21px;
                display: flex;
                align-items: center;
                gap: 50px;
                background-color: #FFA8A7;
                border-radius: 10px;
                font-weight: 500;
                font-family: CatchyMelodyRegular, Courier !important;
        

                img{
                    height: 30px;
                    width: 30px;
                    object-fit: contain;
                }

                p.tab_text{
                    margin: auto 0;
                }
            }


            .listing {
                height: 45px;
                width: 45px;
                border-radius: 50%;
                background: #9A4A4D;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto 0;
                text-align: center;

                p {
                    color: white;
                    font-weight: 700;
                    font-size: 20px;
                    margin: auto;
                }
            }
        }
    }
}

.navigator_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;    
    .ellipse{
        position: relative;
    }

    img.backward_image{
        align-self: center;
        position: relative;
        left: 10px;
        bottom: 37px;
    }
    .img.forward_img{
        align-self: center;
        position: relative;
        left: 10px;
        bottom: 37px;
    }
    
}


.backward_link.disabled, .forward_link.disabled{
    cursor: not-allowed;
    img{
        opacity: .5;

    }
}