@import './layouts/surveys/surveys';

.font-bolder {
  font-weight: 900 !important;
}
.bg-l-primary {
  background-color: #fdf4f5;
}
.cursor-pointer {
  cursor: pointer;
}
.rounded {
  &-5 {
    border-radius: 15px !important;
  }
  &-tl-5 {
    border-top-left-radius: 15px !important;
  }
  &-tr-5 {
    border-top-right-radius: 15px !important;
  }
}

.vertical-layout.vertical-menu-modern .main-menu {
  background-color: $primary !important;
  .main-menu.menu-light .navigation {
    background-color: $primary !important;
  }
}

div.main-menu.menu-fixed.menu-accordion.menu-shadow
  > div.scrollbar-container.main-menu-content.ps
  ul {
  @include media-breakpoint-up(md) {
    background-color: $primary !important;
  }
}

ul .navigation .navigation-main {
  background-color: $primary !important;
}

#root
  > div.wrapper.vertical-layout.navbar-floating.footer-static.vertical-menu-modern.menu-expanded
  > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light
  > div.scrollbar-container.main-menu-content.ps
  > ul
  > li.nav-item.active
  > a {
  background: $secondary !important;
  transform: scale(1.05);
}

#root
  > div.wrapper.vertical-layout.navbar-floating.footer-static.vertical-menu-modern
  > div.main-menu.menu-fixed.menu-accordion.menu-shadow.menu-light
  > div.scrollbar-container.main-menu-content.ps
  > ul
  > li.nav-item.active
  > a {
  transform: scale(1.1);
}

#root
  > div.wrapper.vertical-layout.navbar-floating.footer-static.vertical-menu-modern
  > div.main-menu.menu-fixed.menu-accordion.menu-shadow.menu-light
  > div.scrollbar-container.main-menu-content.ps
  > ul
  li.nav-item
  a {
  background: $light-primary;
}
// #root > div.wrapper.vertical-layout.navbar-floating.footer-static.vertical-menu-modern.menu-collapsed > div.main-menu.menu-fixed.menu-accordion.menu-shadow.menu-light > div.scrollbar-container.main-menu-content.ps > ul > li:nth-child(6) > a

#root
  > div.wrapper.vertical-layout.navbar-static.footer-static.vertical-menu-modern
  > div.main-menu.menu-fixed.menu-accordion.menu-shadow.menu-light
  > div.scrollbar-container.main-menu-content.ps
  > ul
  > li
  > a {
  border-radius: 5px;
  color: white;
  margin-top: 15px;
  background: $light-primary !important;
}

#root
  > div.wrapper.vertical-layout.navbar-floating.footer-static.vertical-menu-modern.menu-expanded
  > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light
  > div.navbar-header
  > ul
  > li.nav-item.nav-toggle
  div
  svg {
  color: white !important;
}

#root
  > div.wrapper.vertical-layout.navbar-floating.footer-static.vertical-menu-modern.menu-collapsed
  > div.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded.menu-light
  > div.navbar-header
  > ul
  > li.nav-item.nav-toggle
  > div
  > svg.text-primary.toggle-icon.d-none.d-xl-block {
  color: white !important;
}
#root
  > div.wrapper.vertical-layout.navbar-static.footer-static.vertical-menu-modern.menu-collapsed {
  background-color: #fff7f7 !important;
}
.clr-white {
  color: white !important;
}

.ecommerce-card {
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.25);
  }
}

.animate-card {
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba($black, 0.25);
  }
}

div.main-menu.menu-fixed.menu-accordion.menu-shadow
  > div.scrollbar-container.main-menu-content.ps
  ul {
  @include media-breakpoint-up(md) {
    background-color: $primary !important;
  }
  @include media-breakpoint-up(sm) {
    background-color: transparent !important;
  }
}

.user-info-wrapper > img {
  @include media-breakpoint-down(lg) {
    position: relative;
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: -107px;
  }
}

// Nav Var Container
.custome-navbar-continer {
  box-shadow: none !important;
}

.scrollbar-container.main-menu-content.ps {
  overflow: unset !important;
}

div.menu-collapsed {
  li.nav-item.active {
    margin-left: 10px;
    a {
      margin-right: 3px;
    }
    a > svg {
      width: 24px !important;
      height: 24px !important;
    }
    h6 {
      font-size: 12px !important;
      padding-left: 10px;
    }
  }
  li.nav-item.active::after {
    content: ' ';
    position: absolute;
    width: 20px;
    height: 120px;
    left: 68px;
    top: -30px;
    // border: 50px #f98f8d solid;
    // border-top-right-radius: 30px;
    // border-bottom-right-radius: 30px;
    // border-left: 50px;
    // border-right: 25px solid #f98f8d;
    background: url('./sidebar_bumb.png');
    background-size: cover;
    // public/assets/images/sidebar_bumb.png
  }
  .main-menu.menu-fixed.menu-accordion.menu-shadow {
    overflow: unset;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
  }
}

// =====================================

$cursive: 'Great Vibes', cursive;
$handwriting: 'Sacramento', cursive;

$white: #fff;
$black: #000;

@mixin hc() {
  left: 50%;
  transform: translatex(-50%);
}

@mixin vc() {
  top: 50%;
  transform: translatey(-50%);
}

@mixin cc() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body {
  // display: grid;
  // top: 0;
  // left: 0;
  // width: 100vw;
  // height: 100vh;
  // margin: 0;
  // padding: 0;
  // font-family: $handwriting;
  // color: $white;
  // background: #333;
  // background-image: url('./bg.png');

  // background-image: radial-gradient(
  //     ellipse at bottom left,
  //     rgba(0, 163, 203, 1) 40%,
  //     rgba(0, 163, 203, 0) 40%
  //   ),
  //   radial-gradient(ellipse at top right, rgba(0, 163, 203, 1) 30%, rgba(0, 163, 203, 0) 30%),
  //   linear-gradient(
  //     to right,
  //     rgba(98, 87, 147, 1) 0%,
  //     rgba(98, 87, 147, 1) 33%,
  //     rgba(213, 93, 100, 1) 33%,
  //     rgba(213, 93, 100, 1) 66%,
  //     rgba(228, 145, 41, 1) 66%
  //   );
  // background-size: 50% 100%;

  // background-position: center center;
  // background-repeat: no-repeat;
  // background-image: url('./bg.jpeg');
  background-color: #ffcae1;

  .card {
    // margin: auto;
    // border-radius: 1em;
    // border: 1px solid transparentize($white, 0.4);
    // background: transparentize($white, 0.5);
    /* From https://css.glass */

    // background: rgba(255, 191, 191, 0.66);
    // border-radius: 16px;
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    // backdrop-filter: blur(7.8px);
    // -webkit-backdrop-filter: blur(7.8px);
    // border: 1px solid rgba(255, 191, 191, 0.3);

    // backdrop-filter: blur(20px);
    // transform: perspective(500px);
    // transform-style: preserve-3d;
    // p span {
    //   color: #000 !important;
    // }
    // .card-header {
    //   text-shadow: 0 0 1em transparentize($black, 0.5);
    // }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;600;700;800&display=swap');
.detail {
  flex-direction: column;
}

// Toast
.Toastify__toast.Toastify__toast--warning .Toastify__progress-bar {
  background-color: $primary;
}

.goal_btn {
  background-color: #9a4a4d;
  color: white;
  border: none;
  width: 10rem;
  border-radius: 3px;
  margin-bottom: 1rem;
  margin: 1rem;
}
