.speedometer {
  position: relative;
  width: 100%;
  height: 100%;
}

.speedometer svg {
  cursor: pointer;
}

.speedometer .cls-1 {
  fill: url(#linear-gradient);
}
.speedometer .cls-2 {
  fill: #231f20;
}
.speedometer .cls-3 {
  fill: #a7a9ac;
}
.speedometer .cls-4 {
  fill: #d1d3d4;
  stroke: #e6e7e8;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}

.speedometer .needle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 110px solid black;
  border-radius: 50px;
  transform-origin: 0 0;
  transform: rotate(-85deg);
}

.speedometer .rainbow-box {
  margin: auto;
  width: max-content;
  padding: 16px;
  color: black;
  font-size: 24px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.needleeeee {
  width: 5px;
  height: 10px;
  position: absolute;
  left: 50%;
  bottom: 10%;
}

.needleeeee::after {
  content: ' ';
  position: absolute;
  left: -15px;
  width: 45px;
  height: 120px;
  bottom: -20px;
  background-image: url('./needle.png');
  background-size: 045px 120px;
  background-repeat: no-repeat, repeat;
}

@media (max-width: 576px) {
  // / styles for small screens /

  .needleeeee::after {
    content: ' ';
    position: absolute;
    left: -15px;
    width: 30px;
    height: 100px;
    bottom: -50px;
    background-image: url('./needle.png');
    background-size: 035px 70px;
    background-repeat: no-repeat, repeat;
  }
}
@media (min-width: 992px) and (max-width: 1140px) {
  .needleeeee::after {
    content: ' ';
    position: absolute;
    left: -15px;
    width: 30px;
    height: 100px;
    bottom: -40px;
    background-image: url('./needle.png');
    background-size: 035px 80px;
    background-repeat: no-repeat, repeat;
  }
}